html,
body {
  height: 100%;
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
  background-color: #111;
  color: #fff;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #326dab;
}

.rotate90 {
  transform: rotate(90deg);
  transform-origin: bottom left;
  position: absolute;
  top: -100vw;
  left: 0;
  height: 100vw;
  width: 100vh;
}

.rotate-90 {
  transform: rotate(-90deg);
  transform-origin: bottom right;
  position: absolute;
  top: -100vw;
  right: 0;
  height: 100vw;
  width: 100vh;
}
